import React from 'react'
import { Link } from 'gatsby'

const Pagination = ({ pageContext, pathPrefix }) => {
  const { previousPagePath, nextPagePath } = pageContext

  return (
<ul className="pagination">
    {previousPagePath ? ( <li> <Link to={`${previousPagePath}#one`} className="button small">Prev</Link> </li> ) : (<li> <Link to={`${previousPagePath}#one`} className="button small disabled">Prev</Link> </li>) }
    {nextPagePath ? (<li> <Link to={`${nextPagePath}#one`} className="button small"> Next </Link> </li> ) :(<li> <Link to={`${nextPagePath}#one`} className="button small disabled"> Next </Link> </li> )}
</ul> )
}

export default Pagination

