import React from "react";
import * as THREE from "three";
import FOG from "vanta/dist/vanta.fog.min";

class BannerBlog extends React.Component {
  constructor() {
    super();
    this.vantaRef = React.createRef();
    this.zoomLevel = 1.0;
      if (typeof window !== `undefined`) {
      const mediaQuery = window.matchMedia('(max-width: 1500px)')
      if (mediaQuery.matches) {
        this.zoomLevel = 0.5;
      } else {
        this.zoomLevel = 1.0;
      }
    }
  }
  componentDidMount() {
    this.vantaEffect = FOG({
      el: this.vantaRef.current,
      THREE: THREE,
      highlightColor: 0xff00f0,
      midtoneColor: 0xb3ff,
      zoom: this.zoomLevel
    });
  }
  componentWillUnmount() {
    if (this.vantaEffect) this.vantaEffect.destroy();
  }
  render() {
    return (
      <section id="banner" className="major" ref={this.vantaRef}>
        <div className="inner">
          <header className="major">
            <h1>Articles</h1>
          </header>
          <div className="content">
            <p>
              The Brandinavian journal explores emerging
              <br />
              technologies and scientific concepts.
            </p>
          </div>
        </div>
      </section>
    );
  }
}
export default BannerBlog;
