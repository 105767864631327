import React from "react";
import SEO from "../components/SEO";
import { graphql } from "gatsby";

import LayoutSmall from "../components/layoutSmall";
import BannerBlog from "../components/BannerBlog";
import BlogCard from "../components/BlogCard";
import Pagination from "../components/Pagination";

class BlogArchive extends React.Component {
  render() {
    const { data, pageContext } = this.props;

      const schema = {
        "@context": "https://schema.org",
        "@graph": [
          {
            "@type": "Organization",
            "@id": "https://brandinavian.com/#organization",
            name: "Brandinavian",
            url: "https://brandinavian.com/",
            sameAs: [
              "https://www.facebook.com/brandinavian/",
              "https://www.instagram.com/brandinavian/",
              "https://www.linkedin.com/company/brandinavian/",
              "https://twitter.com/brandinavian",
            ],
            logo: {
              "@type": "ImageObject",
              "@id": "https://brandinavian.com/#logo",
              inLanguage: "en-US",
              url:
              "https://cdn.brandinavian.com/wp-content/uploads/2020/09/brandinavian-logo.png",
              width: 1112,
              height: 116,
              caption: "Brandinavian | Modern Web Development",
            },
            image: { "@id": "https://brandinavian.com/#logo" },
          },
          {
            "@type": "WebSite",
            "@id": "https://brandinavian.com/#website",
            url: "https://brandinavian.com/",
            name: "Brandinavian",
            description: "USA Web Developer",
            publisher: { "@id": "https://brandinavian.com/#organization" },
            inLanguage: "en-US",
          },
          {
            "@type": ["WebPage", "CollectionPage"],
            "@id": "https://brandinavian.com/articles/#webpage",
            url: "https://brandinavian.com/articles/",
            name: "Articles | Brandinavian",
            isPartOf: { "@id": "https://brandinavian.com/#website" },
            description:
              "The Brandinavian Journal explores emerging technologies and scientific concepts",
            breadcrumb: {
              "@id": "https://brandinavian.com/cro/#breadcrumb",
            },
            inLanguage: "en-US",
            potentialAction: [
              {
                "@type": "ReadAction",
                target: ["https://brandinavian.com/articles"],
              },
            ],
          },
          {
            "@type": "BreadcrumbList",
            "@id": "https://brandinavian.com/articles/#breadcrumb",
            itemListElement: [
              {
                "@type": "ListItem",
                position: 1,
                item: {
                  "@type": "WebPage",
                  "@id": "https://brandinavian.com/",
                  url: "https://brandinavian.com/",
                  name: "Web developer",
                },
              },
              {
                "@type": "ListItem",
                position: 2,
                item: {
                  "@type": "WebPage",
                  "@id": "https://brandinavian.com/articles",
                  url: "https://brandinavian.com/articles",
                  name: "Articles",
                },
              },
            ],
          },
        ],
      };

    return (
      <LayoutSmall altLogo={`black`}>
        <SEO
          lang="en-US"
          title="Articles | Brandinavian"
          schemaMarkup={schema}
          description="The Brandinavian Journal explores emerging technologies and scientific concepts!"
          image="https://cdn.brandinavian.com/wp-content/uploads/2020/10/sciencetech.jpg"
        />

        <BannerBlog />

        <div id="main">
          <section id="one">
            <div className="inner">
              <div className="grid-wrapper">
                {data.allWpPost.nodes.map((node) => (
                  <BlogCard
                    blogImg={
                      node.featuredImage.node.localFile.childImageSharp.fluid
                    }
                    imgAlt={node.featuredImage.node.altText}
                    blogTitle={node.title}
                    blogExcerpt={node.excerpt}
                    authorName={node.customPostMetaACF.whoIsTheAuthor}
                    authorImg={
                      node.customPostMetaACF.authorImage.localFile
                        .childImageSharp.original.src
                    }
                    articleLink={node.slug}
                    publishDate={node.date}
                    cardTags={node.tags}
                    key={node.id}
                  />
                ))}
              </div>
              <Pagination pageContext={pageContext} pathPrefix="/" />
            </div>
          </section>
        </div>
      </LayoutSmall>
    );
  }
}

export default BlogArchive;

export const query = graphql`
  query($skip: Int!, $limit: Int!) {
    allWpPost(sort: { fields: date, order: DESC }, skip: $skip, limit: $limit) {
      nodes {
        title
        excerpt
        slug
        id
        date(formatString: "MMM. D, YYYY")
        customPostMetaACF {
          whoIsTheAuthor
          authorImage {
            localFile {
              childImageSharp {
                original {
                  src
                }
              }
            }
          }
        }
        tags {
          nodes {
            name
            id
          }
        }
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 1000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
