import React from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";

const BlogCard = (props) => (
  <div className="col-4 blog-card">
    <Link to={`/${props.articleLink}`} className="link-reset">
      <Img className="blog-img" fluid={props.blogImg} alt={props.imgAlt} />
    </Link>
    <div className="blog_description">
      <Link to={`/${props.articleLink}`} className="link-reset">
        <h3>{props.blogTitle}</h3>
      </Link>
      <div className="author-bio blog-archive">
        <img className="author-img" src={props.authorImg} alt="Author image" />
        <div className="author-details">
          <p className="author-title">Author</p>
          <p className="author-name">{props.authorName}</p>
        </div>
        <div className="publish-time">
          <p className="publish-title">Published</p>
          <p
            className="publish-timestamp"
            dangerouslySetInnerHTML={{ __html: props.publishDate }}
          />
        </div>
      </div>
      <p
        className="blog_excerpt"
        dangerouslySetInnerHTML={{ __html: props.blogExcerpt }}
      />
      <div className="tag-box">
        {props.cardTags.nodes.map((node) => (
          <li key={node.id} className="tag">{node.name}</li>
        ))}
      </div>
    </div>
  </div>
);

export default BlogCard;
